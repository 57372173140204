import React, { useState } from 'react';
import { useCartServices } from '../actions/cart-services/index';

export default function OrderActions({ canAddToBasket, lineItemId, isItemClone, ...props }) {
  const [buttonWasPressed, setButtonWasPressed] = useState(false);

  const handleSubmit = useCartServices({ lineItemId, isItemClone, ...props });

  const pressHandler = () => {
    if (window.dataLayer && !lineItemId) {
      gtag('event', 'add_to_basket', { params: JSON.stringify({...props.userSelections, ...props.quantity}) });
    }
    if(window.dataLayer && lineItemId && isItemClone) {
      gtag('event', 're_order_modified', {
        modified_line_item_id: lineItemId,
        params: JSON.stringify({...props.userSelections, ...props.quantity})
      });
    }
    setButtonWasPressed(true);
    handleSubmit();
  };

  return (
    <div>
      <div className="row no-gutters fc-actions-row">
        <div className="col-md-6 col-sm-12 fc-add-to-cart">
          <button
            className="btn btn-default btn-block btn-add-to-cart"
            onClick={pressHandler}
            disabled={buttonWasPressed || !canAddToBasket}
          >
            {lineItemId ? 'SAVE CHANGES' : 'ADD TO BASKET'}
          </button>
        </div>
      </div>
    </div>
  );
}
